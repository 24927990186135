<template>

</template>

<script>
export default {
  created() {
    this.$router.replace({ name: 'publications-id-articles' })
  }
}
</script>
